import imageGallery1 from './Screenshot1.png';
import imageGallery2 from './Screenshot2.png'
import imageGallery3 from './Screenshot3.png'
import imageGallery4 from './Screenshot4.png'
import imageGallery5 from './Screenshot5.png'
import imageGallery6 from './Screenshot6.png'
import imageGallery7 from './Screenshot7.png'
import imageGallery8 from './Screenshot8.png'
import imageGallery9 from './Screenshot9.png'
import imageGallery10 from './Screenshot10.png'
import imageGallery11 from './Screenshot11.png'

export const GALLERY_IMAGES_ARRAY: string[] = [
  imageGallery1, imageGallery2, imageGallery3, imageGallery4, imageGallery5, imageGallery6, imageGallery7, imageGallery8, imageGallery9, imageGallery10, imageGallery11 
];
