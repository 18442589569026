import s from "./main-screen.module.css";
import React from "react";
import videoBg from "shared/assets/video_thumbnail.png";
import { Play } from "shared";
import { Modal } from "antd";
import {useTranslate} from '../../../shared/translation/utils';

type Props = {
  isVideoOpen: boolean;
  setIsVideoOpen: (val: boolean) => void;
};

export const MainScreenView: React.FC<Props> = ({
  isVideoOpen,
  setIsVideoOpen,
}) => {
  const t = useTranslate();

  return (
    <div className={s.wrapper}>
      <div className={s.videoWrapper} onClick={() => setIsVideoOpen(true)}>
        <div className={s.videoBgWrapper}>
          <img src={videoBg} alt="video" />
          <div className={s.svgWrapper}>
            <Play />
          </div>
        </div>
        <h1>The Last Eclipse</h1>
      </div>
      <Modal
        open={isVideoOpen}
        onCancel={() => setIsVideoOpen(false)}
        centered
        footer={<></>}
        className={s.modal}
        width={900}
        closeIcon={<></>}
        destroyOnClose
      >
        <iframe
          src={t('videoSrc')}
          title={t('videoTitle')}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          className={s.iframe}
        />
      </Modal>
    </div>
  );
};
